import React from 'react';
import ErrorSection from '../components/ErrorSection';
import Button from '../components/Button';
import { ArrowRight } from 'lucide-react';

export default (props: any) => {
  return (
    <ErrorSection
      logoSrc={props.logoSrc}
      title={props.title}
      subtitle={props.subtitle}
      buttons={[
        <Button variant='primary' href={props.btnUrl} size='md' text={props.btnText} iconAfter={<ArrowRight />} />,
      ]}
    />
  )
}
